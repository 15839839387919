<template>
    <v-row>
        <v-col cols="12" lg="4">
            <v-card class="mx-auto">
                <v-card-title>Usuário de acesso</v-card-title>
                <v-divider />
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-text>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-identifier</v-icon>
                        <span class="font-weight-bold">ID:</span>
                        {{usuario.idusuario}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-account-outline</v-icon>
                        <span class="font-weight-bold">Usuário:</span>
                        {{usuario.usuario}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-account</v-icon>
                        <span class="font-weight-bold">Nome:</span>
                        {{usuario.nome}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-clock</v-icon>
                        <span class="font-weight-bold">Último acesso:</span>
                        {{usuario.dtultimologin}}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <PerfilDialogSenha />
                    <!-- <PerfilDialogDados /> -->
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="12" lg="8">
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title v-if="dados.total > 1">Clientes</v-card-title>
                        <v-card-title v-else>Cliente</v-card-title>
                        <v-divider />
                        <v-progress-linear indeterminate absolute :active="carregando" />
                        <v-card-text>
                            <v-row>
                                <v-col class="pa-2" v-for="(c, i) in dados.lista" :key="i">
                                    <v-card dark color="primary" elevation="0">
                                        <v-card-text class="py-3">
                                            <div class="my-1 text-truncate">
                                                <v-icon class="mr-2">mdi-identifier</v-icon>
                                                <span class="font-weight-bold">ID:</span>
                                                {{c.idcliente}}
                                            </div>
                                            <div class="my-1 text-truncate">
                                                <v-icon class="mr-2">mdi-account</v-icon>
                                                <span class="font-weight-bold">Nome:</span>
                                                {{c.nome}}
                                            </div>
                                            <div class="my-1 text-truncate">
                                                <v-icon class="mr-2">mdi-map-marker</v-icon>
                                                <span class="font-weight-bold">Endereço:</span>
                                                {{c.endereco}}
                                            </div>
                                            <div class="my-1 text-truncate">
                                                <v-icon class="mr-2">mdi-city</v-icon>
                                                <span class="font-weight-bold">Cidade:</span>
                                                {{c.cidade}}
                                            </div>
                                            <div class="my-1 text-truncate">
                                                <v-icon class="mr-2">mdi-phone</v-icon>
                                                <span class="font-weight-bold">Contato:</span>
                                                {{c.contato}}
                                            </div>
                                            <v-divider v-if="dados.total > 1" />
                                            <v-divider v-if="dados.total > 1" />
                                            <v-divider v-if="dados.total > 1" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import PerfilDialogSenha from "../Perfil/PerfilDialogSenha";
//import PerfilDialogDados from '../Perfil/PerfilDialogDados'
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "Perfil",
    components: { PerfilDialogSenha /* PerfilDialogDados */ },
    data: () => ({
        carregando: false,
        dados: {
            lista: [],
            total: 0,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "usuario"]),
    },
    methods: {
        get() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}cliente/listar`)
                .then((res) => {
                    this.dados = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
    },
    created() {
        this.get();
    },
};
</script>

<style scoped>
</style>