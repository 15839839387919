<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="575">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="carregando" color="primary" elevation="0" v-bind="attrs" v-on="on" @click="limpar()">Alterar Senha</v-btn>
        </template>
        <v-card outlined>
            <v-card-title class="text-h5">Alterar Senha</v-card-title>
            <v-card-text>
                <v-divider/>
                <v-form>
                    <v-row>
                        <v-col cols="12" class="mt-8 mb-n8">
                            <v-text-field :disabled="carregando" label="Senha atual" type="password" v-model="senhaAtual" required autocomplete="off"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-n8">
                            <v-text-field :disabled="carregando" label="Senha" type="password" v-model="senha" required autocomplete="off"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :disabled="carregando" label="Confirmar senha" type="password" v-model="confirmarSenha" required autocomplete="off" @keydown.enter="alterarSenha()"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-alert v-if="erro" dismissible icon="mdi-alert-circle-outline" close-icon="mdi-close" type="error" class="mb-0">{{erro}}</v-alert>
                            <v-alert v-if="msg" dismissible icon="mdi-check" close-icon="mdi-close" type="success" class="mb-0">{{msg}}</v-alert>
                        </v-col>
                    </v-row>
                </v-form>                             
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text @click="dialog = false">
                    Fechar
                </v-btn>
                <v-btn :disabled="carregando" :loading="carregando" color="primary" text @click="alterarSenha()">
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import {mapState} from 'vuex'
import axios from 'axios'

export default {
    name : "PerfilDialogSenha",
    data : () => ({
        carregando     : false,
        dialog         : false,
        senhaAtual     : '',
        senha          : '',
        confirmarSenha : '',
        erro           : '',
        msg            : '',
    }),
    computed : {
        ...mapState(['backendUrl', 'usuario'])
    },
    methods : {
        limpar() {
            this.senhaAtual = ''
            this.senha = ''
            this.confirmarSenha = ''
            this.erro = ''
            this.msg = ''
        },
        alterarSenha() {
            this.erro = ''
            this.msg = ''
            this.carregando = true
            if (this.senha == this.confirmarSenha) {
                return axios.post(`${this.backendUrl}usuario/alterarsenha`, {
                    token      : this.usuario.token,
                    novasenha  : this.senha,
                    senhaatual : this.senhaAtual
                }).then( (res) => {
                    console.log(res)
                    this.msg = 'Senha alterada com sucesso.'
                    this.carregando = false
                }).catch((e) => {
                    this.erro = this.erroFormatar(e)
                    this.carregando = false
                })
            } else {
                this.erro = 'A confirmação de senha informada não confere!'
                this.carregando = false
            }
        }
    }
}
</script>